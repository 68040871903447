import React from 'react';

import SEO from "../components/seo"
import { graphql } from 'gatsby';
import { Layout } from '../layout/Layout';
import ContactForm from '../components/modules/contact/ContactForm';

import phoneIcon from '../images/svg/phone.svg';
import mailIcon from '../images/svg/mail.svg';

const ContactPage = ({ data, pageContext, location }) => {
  const { title, message, phone_number, email } = data.directus.contact_page_translations_by_id;
  return (
    <Layout pageId="contact" currentLanguage={pageContext.languageCode}>
      <div className="contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-11 no-gutters mx-auto contact-section__container">
              <div className="col-xl-4 contact-section__info">
                <h1 className="heading">{title}</h1>
                <p className="description">{message}</p>
                <p className="info">
                  <span>
                    <img src={phoneIcon} alt="" />
                  </span>
                  {phone_number}
                </p>
                <p className="info">
                  <span>
                    <img src={mailIcon} alt="" />
                  </span>
                  {email}
                </p>
              </div>
              <ContactForm
                currentLanguage={pageContext.languageCode}
                basePath={pageContext.basePath}
                defaultSubject={location?.state?.subject}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ContactPageQuery($translationId: ID!) {
    directus {
      contact_page_translations_by_id(id: $translationId) {
        title
        message
        phone_number
        email
      }
    }
  }
`;

export default ContactPage;

export const Head = () => <SEO title="Contact - MicroRate" />;
