import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { TextInput, PhoneInputField, SelectInput, TextAreaInput } from '../../forms';
import { ToastContainer, toast } from 'react-toastify';
import useCountryCode from '../../../hooks/useCountryCode';
import 'react-toastify/dist/ReactToastify.css';
import es from 'react-phone-number-input/locale/es';

// Form fields
import {
  submitButton,
  fieldsTranslations,
  countriesTranslations,
  languagesTranslations,
  entitiesTranslations,
  subjectsTranslations,
} from '../../../translations/forms';

export const preferredLanguages = {
  'en-US': 'English',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'pt-BR': 'Português',
};

const Form = ({ currentLanguage, basePath, defaultSubject }) => {
  const data = useStaticQuery(
    graphql`
      query ContactFormQuery {
        site {
          siteMetadata {
            contactFormUrl
          }
        }
      }
    `
  );

  const { contactFormUrl } = data.site.siteMetadata

  const fields = fieldsTranslations[currentLanguage];
  const entities = entitiesTranslations[currentLanguage];
  const subjects = subjectsTranslations[currentLanguage];
  const languages = languagesTranslations[currentLanguage];
  const countries = countriesTranslations[currentLanguage];

  // Get country
  const [countryCode] = useCountryCode();
  const initialCountry = countryCode ? es[countryCode] : '';

  // Get language
  const initialLanguage = preferredLanguages[currentLanguage];

  // If default subject not in list select others
  const initialSubject =
    defaultSubject == null ? '' : Object.values(subjects).includes(defaultSubject) ? defaultSubject : 'Otro';

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      company: '',
      entity: '',
      position: '',
      country: initialCountry,
      language: initialLanguage,
      subject: initialSubject,
      message: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      phone_number: Yup.string().required('Required'),
      company: Yup.string().required('Required'),
      entity: Yup.mixed().oneOf(Object.values(entities)).required('Required'),
      position: Yup.string().required('Required'),
      country: Yup.mixed().oneOf(Object.values(countries)).required('Required'),
      language: Yup.mixed().oneOf(Object.values(languages)).required('Required'),
      subject: Yup.mixed().oneOf(Object.values(subjects)).required('Required'),
      message: Yup.string().required('Required'),
    }),
    onSubmit: async values => {
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: [
            {
              name: 'firstname',
              value: values.first_name,
            },
            {
              name: 'lastname',
              value: values.last_name,
            },
            {
              name: 'email',
              value: values.email,
            },
            {
              name: 'phone',
              value: values.phone_number,
            },
            {
              name: 'company',
              value: values.company,
            },
            {
              name: 'tipo_de_cliente',
              value: values.entity,
            },
            {
              name: 'jobtitle',
              value: values.position,
            },
            {
              name: 'country',
              value: values.country,
            },
            {
              name: 'idioma',
              value: values.language,
            },
            {
              name: 'asunto',
              value: values.subject,
            },
            {
              name: 'message',
              value: values.message,
            },
          ],
        }),
      };

      const response = await fetch(contactFormUrl, options);

      if (response.status === 200) {
        navigate(`${basePath}/gracias`);
      } else {
        toast.error('Ha ocurrido un error. Prueba mas tarde', {
          autoClose: 3000,
          hideProgressBar: true,
        });
      }
    },
  });

  return (
    <form className="col-xl-7 contact-section__form" onSubmit={formik.handleSubmit}>
      <TextInput id="first_name" label={fields.first_name[0]} placeholder={fields.first_name[1]} formik={formik} />
      <TextInput id="last_name" label={fields.last_name[0]} placeholder={fields.last_name[1]} formik={formik} />
      <TextInput id="email" type="email" label={fields.email[0]} placeholder={fields.email[1]} formik={formik} />
      <PhoneInputField
        id="phone_number"
        label={fields.phone_number[0]}
        placeholder="000 000 000"
        formik={formik}
        countryCode={countryCode || 'US'}
      />
      <TextInput id="company" label={fields.company[0]} placeholder={fields.company[1]} formik={formik} />
      <SelectInput
        id="entity"
        label={fields.entity[0]}
        placeholder={fields.entity[1]}
        options={entities}
        formik={formik}
      />
      <TextInput
        id="position"
        label={fields.position[0]}
        placeholder={fields.position[1]}
        formik={formik}
        className="md-col-span-2"
      />
      <SelectInput
        id="country"
        label={fields.country[0]}
        placeholder={fields.country[1]}
        options={countries}
        formik={formik}
      />
      <SelectInput
        id="language"
        label={fields.language[0]}
        placeholder={fields.language[1]}
        options={languages}
        formik={formik}
      />
      <SelectInput
        id="subject"
        label={fields.subject[0]}
        placeholder={fields.subject[1]}
        options={subjects}
        formik={formik}
        className="md-col-span-2"
      />
      <TextAreaInput
        id="message"
        label={fields.message[0]}
        placeholder={fields.message[1]}
        formik={formik}
        className="md-col-span-2"
      />

      <input
        className="btn primary-btn submit-btn md-col-span-2"
        type="submit"
        value={submitButton[currentLanguage]}
        disabled={!formik.isValid}
      />

      <ToastContainer />
    </form>
  );
};

export default Form;
